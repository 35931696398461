import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Spinner from "components/spinner";
import PrivateRoute from "./private-route";
import PrivateRouteAdmin from "./private-route-admin";

const CreateNewRequestPage = lazy(() =>
    import("pages/user/create-new-request/create-new-request-page")
);
// import DashboardPage from "pages/user/dashboard/dashboard-page";
// import UsersMonthlyCalendarPage from "pages/admin/users-monthly-calendar/users-monthly-calendar-page";
// import LoginPage from "pages/login/login-page";
// import SignupPage from "pages/signup/signup-page";
// import ViewAdminTicketPage from "pages/user/view-ticket/view-ticket-page";
// import ViewAndEditUserInformationPage from "pages/admin/view-edit-user-information/view-edit-user-information";
// import PayPalAccountsPage from "pages/admin/paypal/paypal-accounts/paypal-accounts-page";

const IntroducedPeoplePage = lazy(() =>
    import("pages/user/introduced-people/introduced-people-page")
);
const NotificationsContentTab = lazy(() =>
    import("pages/user/notifications/tabs/tab-content/notifications-content")
);
const CheckUserAccountCompleted = lazy(() => import("./check-user-account-completed"));
const PageNotFound = lazy(() => import("pages/404/404"));
const ForgotPasswordPage = lazy(() => import("pages/forgot-password/forgot-password-page"));
const MentorshipPage = lazy(() => import("pages/user/mentorship/mentorship-page"));
const LeaderboardPage = lazy(() => import("pages/user/leaderboard/leaderboard-page"));
const UserUnderLeadershipInfoPage = lazy(() =>
    import("pages/user/user-under-leadership-info/user-under-leadership-info-page")
);
const UserAccountPage = lazy(() => import("pages/user/user-account/user-account-page"));

const SubscriptionPaymentPage = lazy(() =>
    import("pages/user/subscription-payment/subscription-payment-page")
);
const NotificationsPage = lazy(() => import("pages/user/notifications/notifications-page"));
const IncomesInvoicesPage = lazy(() => import("pages/user/incomes-invoices/incomes-invoices-page"));
const PurchaseHistoryPage = lazy(() => import("pages/user/purchase-history/purchase-history-page"));
const AllTicketsPage = lazy(() => import("pages/user/all-tickets/all-tickets-page"));
const SendTicketPage = lazy(() => import("pages/user/send-ticket/send-ticket-page"));
const ViewTicketPage = lazy(() => import("pages/user/view-ticket/view-ticket-page"));
const WithdrawalUserPage = lazy(() => import("pages/user/all-user/withdrawal-users-page"));
const AdminReportsOverview = lazy(() => import("pages/admin/admin-reports/overview/overview"));
const AdminReportsFinancial = lazy(() => import("pages/admin/admin-reports/financial/financial"));
const AdminReportsFinancialDetail = lazy(() =>
    import("pages/admin/admin-reports/financial/financial-detail/financial-detail")
);
const AdminReportsTickets = lazy(() => import("pages/admin/admin-reports/tickets/tickets"));
const AdminReportsClosedTickets = lazy(() =>
    import("pages/admin/admin-reports/closed-tickets/closed-tickets")
);

const AllRequestsPage = lazy(() => import("pages/user/all-requests/all-request-page"));

const RequestDetailsPage = lazy(() => import("pages/user/request-details/request-details-page"));
const AdminReportsInfrastructure = lazy(() =>
    import("pages/admin/admin-reports/Infrastructure/ShowInfrastructurePage")
);
const AdminReportDepartment = lazy(() =>
    import("pages/admin/admin-reports/departments/ShowDepartmentPage")
);

// ------- other page -----

const ContestPage = lazy(() => import("pages/other/Contest"));
const ConnectReportPage = lazy(() => import("pages/other/ConnectReport"));

// ******* Admin Routes
const AllUsersPage = lazy(() => import("pages/admin/all-users/all-users-page"));
const WithdrawalUsersPage = lazy(() => import("pages/admin/all-users/withdrawal-users-page"));
const UsersIncomePage = lazy(() => import("pages/admin/all-users/users-income-page"));
const AdminDashboardPage = lazy(() => import("pages/admin/dashboard/admin-dashboard-page"));
const EditUserPage = lazy(() => import("pages/admin/edit-user/edit-user-page"));
const AllPaymentsPage = lazy(() => import("pages/admin/payments/all-payments-page"));
const AllAdminTicketsPage = lazy(() => import("pages/admin/all-tickets/all-tickets-page"));
const AllNotificationsPage = lazy(() =>
    import("pages/admin/all-notifications/all-notifications-page")
);
const UserInformationPage = lazy(() =>
    import("pages/admin/user-information/user-information-page")
);
const SendNotificationPage = lazy(() =>
    import("pages/admin/send-notification/send-notification-page")
);
const SearchUsersPage = lazy(() => import("pages/admin/search-users/search-users-page"));
const DepartmentAnalysisPage = lazy(() =>
    import("pages/admin/department-analysis/department-analysis-page")
);

const InventoryIncreasePage = lazy(() =>
    import("pages/admin/paypal/inventory-increase/inventory-increase-page")
);

const PayPalAccountDetails = lazy(() =>
    import("pages/admin/paypal/paypal-account-details/paypal-account-details")
);

const WithdrawalTransferMoneyPage = lazy(() =>
    import("pages/admin/paypal/withdrawal-transfer-money")
);

const ViewAndEditUserInformationPage = lazy(() =>
    import("pages/admin/view-edit-user-information/view-edit-user-information")
);
const UsersMonthlyCalendarPage = lazy(() =>
    import("pages/admin/users-monthly-calendar/users-monthly-calendar-page")
);

const SignupPage = lazy(() => import("pages/signup/signup-page"));
const LoginPage = lazy(() => import("pages/login/login-page"));
const DashboardPage = lazy(() => import("pages/user/dashboard/dashboard-page"));
const ViewAdminTicketPage = lazy(() => import("pages/user/view-ticket/view-ticket-page"));
const PayPalAccountsPage = lazy(() =>
    import("pages/admin/paypal/paypal-accounts/paypal-accounts-page")
);
const UserReportPage = lazy(() => import("pages/user/reports/report"));
const UserTicketReportPage = lazy(() => import("pages/user/reports/ticket-report"));
const FinancialConnect = lazy(() =>
    import("pages/user/admin-financial/connect-report/ConnectReportPage")
);
const FinancialConnectAdmin = lazy(() =>
    import("pages/admin/admin-reports/admin-accounting/AdminConnectPage")
);
const FinancialSendUSDT = lazy(() => import("pages/user/admin-financial/send-USDT/sendTokenPage"));
const FinancialSendUSDTAdmin = lazy(() =>
    import("pages/admin/admin-reports/admin-accounting/AdminSendToken")
);

const FinancialBankInfo = lazy(() =>
    import("pages/user/admin-financial/bank-info/BankInfoReportPage")
);
const FinancialBankInfoAdmin = lazy(() =>
    import("pages/admin/admin-reports/admin-accounting/AdminBankInfoPage")
);

const userRoutes = [
    { path: "/withdrawal-users", element: <WithdrawalUserPage /> },
    {
        path: "/",
        element: <DashboardPage />,
    },

    {
        path: "/user-account",
        element: <UserAccountPage />,
    },
    {
        path: "/mentorship",
        element: <MentorshipPage />,
    },
    {
        path: "/all-requests",
        element: <AllRequestsPage />,
    },
    {
        path: "/request-details",
        element: <RequestDetailsPage />,
    },
    {
        path: "/create-new-request",
        element: <CreateNewRequestPage />,
    },
    {
        path: "/user-under-leadership-info",
        element: <UserUnderLeadershipInfoPage />,
    },
    {
        path: "/notifications",
        element: (
            <CheckUserAccountCompleted titlePage="اعلانات">
                <NotificationsPage />
            </CheckUserAccountCompleted>
        ),
        children: [
            {
                path: ":type",
                element: <NotificationsContentTab />,
            },
        ],
    },
    {
        path: "/subscription-payment",
        element: (
            <CheckUserAccountCompleted titlePage="تمدید اشتراک">
                <SubscriptionPaymentPage />
            </CheckUserAccountCompleted>
        ),
    },
    {
        path: "/incomes-invoices",
        element: (
            <CheckUserAccountCompleted titlePage="درآمدها و فاکتورها">
                <IncomesInvoicesPage />
            </CheckUserAccountCompleted>
        ),
    },
    {
        path: "/purchase-history",
        element: (
            <CheckUserAccountCompleted titlePage="سوابق خرید اشتراک">
                <PurchaseHistoryPage />
            </CheckUserAccountCompleted>
        ),
    },
    {
        path: "/leaderboard",
        element: <LeaderboardPage />,
    },
    {
        path: "/all-tickets",
        element: <AllTicketsPage />,
    },
    {
        path: "introduced-people",
        element: <IntroducedPeoplePage />,
    },
    {
        path: "/send-ticket",
        element: <SendTicketPage />,
    },
    {
        path: "/view-ticket",
        element: <ViewTicketPage />,
    },
    {
        path: "/reports",
        element: <UserReportPage />,
    },
    {
        path: "/ticket-report",
        element: <UserTicketReportPage />,
    },
    {
        path: "/financial-connect",
        element: <FinancialConnect />,
    },
    {
        path: "/financial-send-USDT",
        element: <FinancialSendUSDT />,
    },
    {
        path: "/financial-bank-info",
        element: <FinancialBankInfo />,
    },
];

const adminRoutes = [
    { path: "/admin/payments", element: <AllPaymentsPage /> },
    {
        path: "/admin/view-edit-user",
        element: <ViewAndEditUserInformationPage />,
        children: [
            { path: "edit-user", element: <EditUserPage /> },
            { path: "user-information", element: <UserInformationPage /> },
        ],
    },
    { path: "/admin/all-users", element: <AllUsersPage /> },
    { path: "/admin/withdrawal-users", element: <WithdrawalUsersPage /> },
    { path: "/admin/users-income", element: <UsersIncomePage /> },
    { path: "/admin/dashboard", element: <AdminDashboardPage /> },
    { path: "/admin/all-tickets", element: <AllAdminTicketsPage /> },
    { path: "/admin/view-ticket", element: <ViewAdminTicketPage /> },
    { path: "/admin/all-notifications", element: <AllNotificationsPage /> },
    { path: "/admin/send-notification", element: <SendNotificationPage /> },
    { path: "/admin/search-users", element: <SearchUsersPage /> },
    { path: "/admin/users-calendar", element: <UsersMonthlyCalendarPage /> },
    { path: "/admin/department-analysis", element: <DepartmentAnalysisPage /> },
    { path: "/admin/paypal-accounts", element: <PayPalAccountsPage /> },
    {
        path: "/admin/paypal-account-details/:uuid",
        element: <PayPalAccountDetails />,
    },
    { path: "/admin/inventory-increase", element: <InventoryIncreasePage /> },
    {
        path: "/admin/withdrawal-transfer-money",
        element: <WithdrawalTransferMoneyPage />,
    },
    { path: "/admin/admin-reports/overview", element: <AdminReportsOverview /> },
    { path: "/admin/admin-reports/financial", element: <AdminReportsFinancial /> },
    {
        path: "/admin/admin-reports/financial/financial-detail",
        element: <AdminReportsFinancialDetail />,
    },
    {
        path: "/admin/admin-reports/tickets",
        element: <AdminReportsTickets />,
    },
    {
        path: "/admin/admin-reports/closed-tickets",
        element: <AdminReportsClosedTickets />,
    },
    {
        path: "/admin/admin-reports/infrastructure",
        element: <AdminReportsInfrastructure />,
    },
    {
        path: "/admin/admin-reports/departments",
        element: <AdminReportDepartment />,
    },
    {
        path: "/admin/financial-connect-admin",
        element: <FinancialConnectAdmin />,
    },
    {
        path: "/admin/financial-send-USDT",
        element: <FinancialSendUSDTAdmin />,
    },
    {
        path: "/admin/financial-bank-info",
        element: <FinancialBankInfoAdmin />,
    },
];

const AllRoutes = () => {
    return (
        // User routes
        <Suspense
            fallback={
                <div className="h-screen w-full flex justify-center items-center bg-panel">
                    <Spinner />
                </div>
            }
        >
            <Routes>
                <Route path="/signup" element={<SignupPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route path="/contest" element={<ContestPage />} />
                <Route path="/connect-report" element={<ConnectReportPage />} />

                {/* <Route
          path="/"
          element={
            <PrivateRoute>
              <DashboardPage />
            </PrivateRoute>
          }
        />
        <Route path="/user-account" element={<UserAccountPage />} />
        <Route path="/mentorship" element={<MentorshipPage />} />
        <Route path="/all-requests" element={<AllRequestsPage />} />
        <Route path="/request-details" element={<RequestDetailsPage />} />
        <Route path="/create-new-request" element={<CreateNewRequestPage />} />

        <Route
          path="/user-under-leadership-info"
          element={<UserUnderLeadershipInfoPage />}
        />
        <Route
          path="/notifications"
          element={
            <CheckUserAccountCompleted titlePage="اعلانات">
              <NotificationsPage />
            </CheckUserAccountCompleted>
          }
        >
          <Route path=":type" element={<NotificationsContentTab />} />
        </Route>
        <Route
          path="/subscription-payment"
          element={
            <CheckUserAccountCompleted titlePage="تمدید اشتراک">
              <SubscriptionPaymentPage />
            </CheckUserAccountCompleted>
          }
        />
        <Route
          path="/incomes-invoices"
          element={
            <CheckUserAccountCompleted titlePage="درآمدها و فاکتورها">
              <IncomesInvoicesPage />
            </CheckUserAccountCompleted>
          }
        />
        <Route
          path="/purchase-history"
          element={
            <CheckUserAccountCompleted titlePage="سوابق خرید اشتراک">
              <PurchaseHistoryPage />
            </CheckUserAccountCompleted>
          }
        />

        <Route path="/leaderboard" element={<LeaderboardPage />} />
        <Route path="/all-tickets" element={<AllTicketsPage />} />
        <Route path="introduced-people" element={<IntroducedPeoplePage />} />
        <Route path="/send-ticket" element={<SendTicketPage />} />
        <Route path="/view-ticket" element={<ViewTicketPage />} /> */}

                {userRoutes.map((route) =>
                    route.children ? (
                        <Route
                            path={route.path}
                            element={<PrivateRoute>{route.element}</PrivateRoute>}
                        >
                            {route.children.map((childRoute) => (
                                <Route
                                    path={childRoute.path}
                                    element={<PrivateRoute>{childRoute.element}</PrivateRoute>}
                                />
                            ))}
                        </Route>
                    ) : (
                        <Route
                            path={route.path}
                            element={<PrivateRoute>{route.element}</PrivateRoute>}
                        />
                    )
                )}

                {/* Admin routes */}

                {adminRoutes.map((route) =>
                    route.children ? (
                        <Route
                            path={route.path}
                            element={<PrivateRouteAdmin>{route.element}</PrivateRouteAdmin>}
                        >
                            {route.children.map((childRoute) => (
                                <Route
                                    path={childRoute.path}
                                    element={
                                        <PrivateRouteAdmin>{childRoute.element}</PrivateRouteAdmin>
                                    }
                                />
                            ))}
                        </Route>
                    ) : (
                        <Route
                            path={route.path}
                            element={<PrivateRouteAdmin>{route.element}</PrivateRouteAdmin>}
                        />
                    )
                )}

                {/* <Route
          path="/admin/payments"
          element={
            <PrivateRouteAdmin>
              <AllPaymentsPage />
            </PrivateRouteAdmin>
          }
        />
        <Route
          path="/admin/all-users"
          element={
            <PrivateRouteAdmin>
              <AllUsersPage />
            </PrivateRouteAdmin>
          }
        />
        <Route path="/admin/dashboard" element={<AdminDashboardPage />} />
        <Route path="/admin/all-tickets" element={<AllAdminTicketsPage />} />
        <Route path="/admin/view-ticket" element={<ViewAdminTicketPage />} />
        <Route
          path="/admin/all-notifications"
          element={<AllNotificationsPage />}
        />
        <Route
          path="/admin/view-edit-user"
          element={<ViewAndEditUserInformationPage />}
        >
          <Route index path="edit-user" element={<EditUserPage />} />
          <Route path="user-information" element={<UserInformationPage />} />
        </Route>
        <Route
          path="/admin/send-notification"
          element={<SendNotificationPage />}
        />
        <Route path="/admin/search-users" element={<SearchUsersPage />} />
        <Route
          path="/admin/users-calendar"
          element={<UsersMonthlyCalendarPage />}
        />
        <Route
          path="/admin/department-analysis"
          element={<DepartmentAnalysisPage />}
        />
        {/* recently added */}
                {/* <Route path="/admin/paypal-accounts" element={<PayPalAccountsPage />} />

        dynamic route because for filter by date need to uuid is fixed in url
        <Route
          path="/admin/paypal-account-details/:uuid"
          element={<PayPalAccountDetails />}
        />

        <Route
          path="/admin/inventory-increase"
          element={<InventoryIncreasePage />}
        />
        <Route
          path="/admin/withdrawal-transfer-money"
          element={<WithdrawalTransferMoneyPage />}
        />  */}
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </Suspense>
    );
};

export default AllRoutes;
